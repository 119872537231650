<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        In the workup for this reaction, isopropanol is added to remove the excess sodium
        hypochlorite (i.e. the oxidizing agent). Which of the following is the product of this
        oxidation?
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mb-0">
        <v-radio v-for="option in options" :key="option.value" :value="option.value" class="my-3">
          <template #label>
            <v-img :src="option.img" :style="option.width" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI51LCPL4AQ4',
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
      items: [
        {img: '/img/assignments/uciPL4AQ4_optionA.png', value: 'a', width: 'max-width: 81px'},
        {img: '/img/assignments/uciPL4AQ4_optionB.png', value: 'b', width: 'max-width: 81px'},
        {img: '/img/assignments/uciPL4AQ4_optionC.png', value: 'c', width: 'max-width: 113px'},
        {img: '/img/assignments/uciPL4AQ4_optionD.png', value: 'd', width: 'max-width: 81px'},
        {img: '/img/assignments/uciPL4AQ4_optionE.png', value: 'e', width: 'max-width: 113px'},
      ],
    };
  },
  computed: {
    image1Name() {
      return '/img/assignments/ChemUCI51LCPL3Q3_main.png';
    },
    options() {
      return seededShuffle(this.items, this.seed);
    },
    seed() {
      return this.$inertia.getSeed();
    },
  },
};
</script>
<style scoped></style>
